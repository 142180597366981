import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { scope: String }
  static targets = [
    'optionSelect', 'price', 'image', 'label', 'hiddenField', 'addToCartContent', 'addToCartButton',
    'quantityField', 'productUnavailableContent', 'productOutOfStockContent', 'deliveryEstimates',
    'deliveryEstimateOption', 'msrp', 'variantLink'
  ]

  connect() {
    this.twisterJson = JSON.parse(this.element.dataset.productTwisterDataValue)
    this.updateSelectedVariant()

    if (this.scopeValue == 'single') {
      this.updateBrowserUrl()
      this.updateRecentlyViewedCookie()
      this.updateDeliveryEstimateOptions()
    }
  }

  optionSelected(event) {
    this.updateSelectedVariant()
    this.updatePrice()
    this.updateMsrp()
    this.updateImage()
    this.updateLabels()
    this.updateVariantLinks()
    this.updateHiddenField()
    this.updateDom()

    if (this.scopeValue == 'single') {
      this.updateBrowserUrl()
      this.updateRecentlyViewedCookie()
      this.updateDeliveryEstimateOptions()
    }
  }

  currentPartNumber() {
    return this.selectedVariant ? this.selectedVariant.part_number : ''
  }

  currentQuantity() {
    return this.quantityFieldTarget.value
  }

  updateSelectedVariant() {
    let selectedOptions = {};

    this.optionSelectTargets.forEach((optionSelect) => {
      if (!optionSelect.checked) return;
      selectedOptions[optionSelect.name] = optionSelect.value;
    })

    let possibleVariants = this.twisterJson.variants;

    possibleVariants = possibleVariants.filter(variant => {
      for (var key in selectedOptions) {
        if (variant[key] === undefined || variant[key] != selectedOptions[key]) {
          return false;
        }
      }
      return true;
    });

    this.selectedVariant = possibleVariants[0]
  }

  formattedPrice(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  updatePrice() {
    const value = this.selectedVariant ? this.formattedPrice(this.selectedVariant.price) : '-'
    this.priceTarget.innerHTML = value;
  }

  updateMsrp() {
    const price = this.selectedVariant ? this.selectedVariant.price : ''
    const msrp = this.selectedVariant ? this.selectedVariant.msrp : ''

    if (!this.hasMsrpTarget) return;

    if (price == '' || msrp == '' || price >= msrp) {
      this.msrpTarget.innerHTML = ''
    } else {
      this.msrpTarget.innerHTML = this.formattedPrice(msrp)
    }
  }

  updateImage() {
    let src = ''
    if (this.selectedVariant) { src = this.selectedVariant.main_listing_image }
    if (src == '') { src = this.imageTarget.dataset.defaultImage }

    this.imageTarget.src = src;
    this.imageTarget.dataset.zoom = src;
  }

  updateLabels() {
    this.labelTargets.forEach((label) => {
      let value = this.selectedVariant ? this.selectedVariant[label.dataset.labelType] : '-'
      label.innerHTML = value;
    })
  }

  updateHiddenField() {
    this.hiddenFieldTarget.value = this.currentPartNumber();
  }

  updateDom() {
    if (this.selectedVariant) {
      if (this.selectedVariant.quantity_available == 0) {
        this.toggleOutOfStock();
      } else {
        this.updateDeliveryEstimates()
        this.toggleInStock();
      }
    } else {
      this.toggleUnavailable();
    }
  }

  updateVariantLinks() {
    if (!this.selectedVariant) return;

    this.variantLinkTargets.forEach((variantLink) => {
      variantLink.href = this.selectedVariant.url
    })
  }

  updateBrowserUrl() {
    let url = this.selectedVariant.url
    let queryParams = new URLSearchParams(window.location.search);

    if (queryParams.size != 0) {
      url = url + '?' + queryParams.toString()
    }

    history.replaceState(null, null, url);
  }

  quantityUpdated() {
    this.updateDeliveryEstimateOptions()
  }

  toggleDeliveryEstimates(boolean) {
    if (!this.hasDeliveryEstimatesTarget) return;

    this.deliveryEstimatesTarget.classList.toggle('d-none', !boolean)
  }


  updateDeliveryEstimates() {
    if (!this.hasDeliveryEstimatesTarget) return;

    this.deliveryEstimatesTarget.src = `/delivery-estimates?part-number=${this.currentPartNumber()}&quantity=${this.currentQuantity()}`
  }

  updateDeliveryEstimateOptions() {
    this.deliveryEstimateOptionTargets.forEach((deliveryEstimateOption) => {
      let warehouseAvailable = parseInt(deliveryEstimateOption.dataset.quantityAvailable)
      let showMoreLink = deliveryEstimateOption.querySelector('.delivery-estimates--option--link')
      showMoreLink.href = `/delivery-estimates/${this.currentPartNumber()}?quantity=${this.currentQuantity()}`

      if (warehouseAvailable >= this.currentQuantity()) {
        showMoreLink.classList.add('d-none')
      } else {
        showMoreLink.classList.remove('d-none')
      }
    })
  }

  addedToCart() {
    this.quantityFieldTarget.value = 1;

    this.addToCartButtonTarget.innerHTML = 'Added to Cart!';
    this.addToCartButtonTarget.classList.add('btn-success');
    this.addToCartButtonTarget.classList.remove('btn-primary');

    var that = this;

    this.timeout = setTimeout(() => {
      that.addToCartButtonTarget.innerHTML = that.addToCartContentTarget.innerHTML;
      that.addToCartButtonTarget.classList.add('btn-primary');
      that.addToCartButtonTarget.classList.remove('btn-success');
    }, 1500);
  };

  toggleUnavailable() {
    this.toggleDeliveryEstimates(false);
    this.addToCartButtonTarget.disabled = true;
    this.addToCartButtonTarget.innerHTML = this.productUnavailableContentTarget.innerHTML;
    this.addToCartButtonTarget.classList.remove('btn-primary');
    this.addToCartButtonTarget.classList.add('btn-dark');
  }

  toggleOutOfStock() {
    this.toggleDeliveryEstimates(false);
    this.addToCartButtonTarget.disabled = true;
    this.addToCartButtonTarget.innerHTML = this.productOutOfStockContentTarget.innerHTML;
    this.addToCartButtonTarget.classList.remove('btn-primary', 'btn-dark');
    this.addToCartButtonTarget.classList.add('btn-dark');
  }

  toggleInStock() {
    this.toggleDeliveryEstimates(true);
    this.addToCartButtonTarget.disabled = false;
    this.addToCartButtonTarget.innerHTML = this.addToCartContentTarget.innerHTML;
    this.addToCartButtonTarget.classList.add('btn-primary');
    this.addToCartButtonTarget.classList.remove('btn-dark');
  }

  getCookie(cName) {
    const name = cName + "=";
    const decoded = decodeURIComponent(document.cookie); //to be careful
    const cookieArray = decoded.split('; ');
    let res;
    cookieArray.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  }

  updateRecentlyViewedCookie() {
    const partNumber = this.currentPartNumber();
    if (!partNumber) return;

    const cookieName = 'recently_viewed';
    const recentlyViewed = this.getCookie(cookieName);
    const recentlyViewedArray = recentlyViewed ? recentlyViewed.split(',') : [];
    const index = recentlyViewedArray.indexOf(partNumber);

    if (index !== -1) {
      recentlyViewedArray.splice(index, 1);
    }

    recentlyViewedArray.unshift(partNumber);

    if (recentlyViewedArray.length > 10) {
      recentlyViewedArray.pop();
    }

    this.setCookie(cookieName, recentlyViewedArray.join(','), 30);
  }
}